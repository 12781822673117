import "./app.postcss";
import App from "./App.svelte";

import * as Sentry from "@sentry/svelte";

Sentry.init({
    dsn: "https://02d66a53ad644e09ad5f362af926dd31@o4504916038516736.ingest.sentry.io/4505345050607616",
    integrations: [
        new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^http:\/\/isak.mendelu.cz/],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const app = new App({
  target: document.getElementById("app"),
});

export default app;
